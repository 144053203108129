<style lang="less" scoped></style>

<template>
  <div>
    <form
      id="excelFileForm"
      ref="multiImport"
      class="none"
      method="post"
      enctype="multipart/form-data"
    >
      <input
        type="file"
        id="excelFile"
        @change="selectedExcelFile"
        name="upfile"
      />
    </form>

    <el-form
      ref="excelForm"
      :model="deviceInfo"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="excel附件:" prop="zipfile">
        <el-button id="excelFileButton" @click="triggerSelectFile">
          上传附件
        </el-button>
        <span class="m-l-xs">{{ excelFileName }}</span>
      </el-form-item>
      <el-form-item label="批次:" v-if="isAdmin" prop="batch">
        <el-input
          v-model="deviceInfo.batch"
          placeholder="请输入批次"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="isAdmin" label="品牌商">
        <el-select
          style="width:100%;"
          v-model="deviceInfo.customer"
          filterable
          remote
          clearable
          :remote-method="queryBrander"
          @change="brandChange"
          placeholder="选择品牌商"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-else label="经销商">
        <el-select
          style="width:100%;"
          v-model="deviceInfo.customer"
          filterable
          clearable
          placeholder="选择经销商"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="产品型号:"
        v-if="isAdmin"
        prop="publicNumberproductTypeId"
      >
        <el-select
          v-model="deviceInfo.publicNumberproductTypeId"
          placeholder="请选择"
          style="width:100%;"
        >
          <el-option
            v-for="item in brandTypeList"
            :key="item.id"
            :label="item.deviceTypeName + ' （品牌：' + item.productName + '）'"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <!--  <el-form-item label="设备描述:" v-if="isAdmin">
        <el-input type="textarea" :rows="5" :maxlength="200" v-model="deviceInfo.remarks"
                  placeholder="请输入设备描述"></el-input>
      </el-form-item>-->
      <el-form-item class="text-right m-t-md">
        <el-button
          type="primary"
          size="large"
          @click="submitMultiAuthorize('excelForm')"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { queryBrander, queryProxy } from "../../../services/customer";
  import {
    searchProByCustomer,
    multiCreateAuthorize,
    multiCreateDistribute,
  } from "../../../services/device";
  import { Notification } from "element-ui";
  export default {
    data() {
      return {
        deviceInfo: {
          customer: "",
          publicNumberproductTypeId: "",
          remarks: "",
          batch: "",
        },
        brandList: [],
        brandTypeList: [],
        excelFileName: "",
        rules: {
          batch: [{ required: true, message: "请输入", trigger: "blur" }],
          publicNumberproductTypeId: [
            { required: true, message: "请选择", trigger: "change" },
          ],
        },
      };
    },
    props: {
      isClear: {
        type: Boolean,
        default: true,
      },
      isAdmin: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
    },
    created() {
      if (this.isAdmin) {
        this.queryBrander("");
      } else {
        this.queryProxy();
      }
    },
    methods: {
      /**
       * 获取所有品牌商
       */
      async queryBrander(key) {
        if (this.isAdmin) {
          const res = await queryBrander({ isProxy: true });
          if (res && res.errorCode === 0) {
            this.brandList = (res && res.data) || [];
            this.queryBrandTypeList(this.deviceInfo.customer);
          }
          this.loaded = true;
          this.listLoading = false;
        }
      },

      brandChange(val) {
        if (val) {
          this.deviceInfo.publicNumberproductTypeId = "";
          this.queryBrandTypeList();
        }
      },

      /**
       * 获取品牌型号列表
       */
      async queryBrandTypeList() {
        const customer = this.deviceInfo.customer;
        const responseData = await searchProByCustomer(
          { customer: customer },
          { size: 999, page: 1 }
        );
        if (responseData.errorCode === 0) {
          this.brandTypeList =
            (responseData && responseData.data && responseData.data.list) || [];
        }
      },
      /**
       * 获取经销商
       */
      async queryProxy() {
        const res = await queryProxy();
        if (res && res.errorCode === 0) {
          this.brandList = (res && res.data) || [];
        }
      },
      /**
       * 触发系统的file选择
       */
      triggerSelectFile() {
        document.querySelector("#excelFile").click();
      },
      /**
       * 选择了zip的文件
       */
      selectedExcelFile($event) {
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }
        document.querySelector("#excelFileButton").blur();
        if (
          file &&
          (file.name.includes(".xls") || file.name.includes(".xlsx"))
        ) {
          if (file.size / 1024 / 1024 / 100 > 1) {
            //100M
            Notification.error({
              title: "错误",
              message: "请选择一个小于100M的xls、xlsx文件",
            });
            return;
          }
          this.excelFileName = file.name;
        } else {
          Notification.error({
            title: "错误",
            message: "请选择一个xls、xlsx文件",
          });
          return;
        }
        //this.fileImageUrl = getFileUrl('#headImgFile');
      },
      /**
       * 批量授权
       */
      async multiCreateAuth() {
        const $file = document.querySelector("#excelFile");
        if (!($file && $file.files.length > 0)) {
          Notification.error({
            title: "错误",
            message: "没有选中的文件",
          });
          return;
        }
        let responseData = null;
        if (this.isAdmin) {
          responseData = await multiCreateAuthorize(
            $file.files[0],
            this.deviceInfo
          );
        } else {
          responseData = await multiCreateDistribute(
            $file.files[0],
            this.deviceInfo
          );
        }

        if (
          responseData &&
          responseData.errorCode === 0 &&
          responseData.data &&
          responseData.data.result
        ) {
          this.multiAuthVisible = false;
          Notification.success({
            title: "成功",
            message: "批量导入成功",
          });
          this.$emit("closeModel");
          // this.queryAuthorizes();
        } else {
        }
      },
      async submitMultiAuthorize() {
        this.$refs.excelForm.validate((valid) => {
          if (valid) {
            this.multiCreateAuth();
            this.clearForm();
          } else {
            return false;
          }
        });
      },
      clearForm() {
        this.deviceInfo = {
          customer: "",
          publicNumberproductTypeId: "",
          remarks: "",
          batch: "",
        };
        this.excelFileName = "";
        this.$refs.excelForm.resetFields();
      },
    },
    watch: {
      isClear(newVal) {
        if (!newVal) {
          (this.deviceInfo = {
            customer: "",
            publicNumberproductTypeId: "",
            remarks: "",
            batch: "",
          }),
            (this.excelFileName = "");
          this.$refs.excelForm.resetFields();
        }
      },
    },
  };
</script>
