<style lang="less" scoped>
  .device-detail {
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        设备管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        授权列表
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div v-on:keyup.enter="queryDevices" class="page-container clearfix">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 364px"
              v-model="filters.key"
              placeholder="设备名称/Mac地址/SN"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-select
              remote
              clearable
              filterable
              v-model="filters.deviceTypeId"
              placeholder="产品型号"
            >
              <el-option
                v-for="item in deviceTypeList"
                :key="item.id"
                :label="`${item.deviceTypeCode}${item.productTypeCode}`"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              remote
              clearable
              filterable
              v-model="filters.productTypeId"
              placeholder="品牌型号"
            >
              <el-option
                v-for="item in brandTypeList"
                :key="item.id"
                :label="`${item.belongsType}${item.belongsSeries}`"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input
              style="width: 150px"
              v-model.number="filters.batch"
              type="number"
              placeholder="批次"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table
        :data="deviceList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>

        <el-table-column
          prop="name"
          width="200"
          label="设备名称"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column
          prop="mac"
          min-width="130"
          label="MAC地址"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="产品型号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.deviceTypeCode + scope.row.productTypeCode }}
          </template>
        </el-table-column>

        <el-table-column label="品牌型号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.belongsType + scope.row.belongsSeries }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="sn"
          :show-overflow-tooltip="true"
          label="SN"
        ></el-table-column>

        <el-table-column
          min-width="100"
          align="center"
          :show-overflow-tooltip="true"
          label="是否销售"
        >
          <template slot-scope="scope">
            {{ scope.row.status ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column
          prop="batch"
          width="100"
          :show-overflow-tooltip="true"
          label="批次"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.batch ? scope.row.batch : "暂无" }}
          </template>
        </el-table-column>

        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :formatter="formatTime"
          label="创建时间"
        ></el-table-column>

        <el-table-column
          prop="remarks"
          align="center"
          :show-overflow-tooltip="true"
          label="备注"
        ></el-table-column>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              class="m-l-xs"
              @click="handelEdit(scope.row)"
            >
              修改
            </el-button>

            <el-button
              type="primary"
              size="small"
              class="m-l-xs"
              @click="godetail(scope.row)"
            >
              详情
            </el-button>

            <el-button
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <a class="hidden" ref="downloadA" :href="downloadUrl" download></a>

        <el-button type="primary" @click="authoShow = true">
          授&nbsp;&nbsp;权
        </el-button>

        <el-button type="warning" @click="volumeAuth">
          批量授权
        </el-button>

        <el-button type="success" @click="exportExcel">
          批量导出
        </el-button>

        <el-button
          type="danger"
          @click="initDvc"
          :disabled="this.sels.length === 0 || !editPermission"
        >
          初始化
        </el-button>

        <a
          class="el-button el-button--primary"
          :href="API_ROOT + '/template/deviceTemplate.xls'"
        >
          下载模板
        </a>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      size="small"
      width="700px"
      :title="isEdit ? '修改' : '授权'"
      :close-on-click-modal="false"
      :visible.sync="authoShow"
    >
      <el-form
        ref="deviceInfoForm"
        :rules="rules"
        :model="deviceInfo"
        label-width="90px"
      >
        <el-form-item v-if="!isEdit" label="品牌型号:" prop="productTypeId">
          <el-select
            clearable
            filterable
            remote
            :remote-method="queryBrands"
            v-model="deviceInfo.productTypeId"
            placeholder="请选择"
            style="width:100%;"
          >
            <el-option
              v-for="item in brandTypeList"
              :key="item.id"
              :label="
                `${item.customerName}(${item.belongsType + item.belongsSeries})`
              "
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!isEdit" label="授权批次:" prop="batch">
          <el-input
            v-model="deviceInfo.batch"
            type="number"
            placeholder="请输入终端预设密钥"
          ></el-input>
        </el-form-item>

        <el-form-item label="SN编号:" prop="sn">
          <el-input v-model="deviceInfo.sn" placeholder="请输入sn码"></el-input>
        </el-form-item>

        <el-form-item label="mac编号:" v-if="!isEdit" prop="mac">
          <el-input
            v-model="deviceInfo.mac"
            placeholder="请输入mac地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="备注信息:" :prop="isEdit ? 'remarks' : ''">
          <el-input
            type="textarea"
            :rows="5"
            :maxlength="200"
            v-model="deviceInfo.remarks"
            placeholder="请输入设备描述"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="margin-top: -20px">
        <el-button @click="authoShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDvc">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量授权" :visible.sync="batchAuthShow">
      <batch-autho
        :isAdmin="isAdmin"
        @closeModel="closeMuitlBatch"
      ></batch-autho>
    </el-dialog>

    <el-dialog title="授权详情" :visible.sync="authoDetailShow">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称：">
              {{ choosedAuthoDvc.customerName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品型号：">
              {{
                choosedAuthoDvc.deviceTypeCode + choosedAuthoDvc.productTypeCode
              }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="Mac地址：">
              {{ choosedAuthoDvc.mac }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="SN  码：">
              {{ choosedAuthoDvc.sn }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="品  牌  商：">
              {{ choosedAuthoDvc.customerName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品牌型号：">
              {{ choosedAuthoDvc.customerName }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="批 次：">
              {{ choosedAuthoDvc.batch }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="相关备注：">
              {{ choosedAuthoDvc.remarks }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="authoDetailShow = false">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import moment from "moment";
  import batch from "./batch.vue";
  import { mapGetters, mapActions } from "vuex";
  import { PAGE_SIZE, API_ROOT } from "src/config";
  import { getAuthorModel } from "src/services/customer";
  import { valiInput, valiSelect } from "src/utils/validator/element";
  import {
    changeDvcSn,
    initDvcById,
    createDevice,
    searchAuthoDvc,
    exportAuthorize,
    delDvcAuthorize,
    searchProByCustomer,
    searchDevicesByType,
  } from "src/services/device";

  export default {
    data() {
      return {
        API_ROOT,
        PAGE_SIZE,
        isAdmin: false,
        isProxy: false,
        isEdit: false,
        authoShow: false,
        listLoading: false,
        batchAuthShow: false,
        editPermission: true,
        authoDetailShow: false,
        total: 1,
        downloadUrl: "",
        choosedRowIndex: -1,
        sels: [], //列表选中列
        deviceList: [],
        brandTypeList: [], //品牌列表
        deviceTypeList: [], //品牌列表
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        filters: {
          key: null,
          customer: null,
          batch: null,
          deviceTypeId: "",
        },
        deviceInfo: {
          sn: "",
          batch: "",
          productTypeId: "",
          macAddr: "",
          remarks: "",
        },
        choosedAuthoDvc: {}, //选中的详情设备
        rules: {
          customer: [{ required: true, message: "请选择", trigger: "change" }],
          productTypeId: [
            { required: true, message: "请选择", trigger: "change" },
          ],
          macAddr: [{ required: true, message: "请输入", trigger: "blur" }],
          batch: [{ required: true, message: "请输入", trigger: "blur" }],
          sn: [{ required: true, message: "请输入", trigger: "blur" }],
          mac: [{ required: true, message: "请输入", trigger: "blur" }],
          remarks: [{ required: true, message: "请输入", trigger: "blur" }],
        },
      };
    },
    components: {
      "batch-autho": batch,
    },
    computed: {
      ...mapGetters({
        user: "userInfo",
        roles: "roles",
      }),
      pms() {
        return this.user.permissions;
      },
    },
    methods: {
      // checkbox勾选的变动的回调
      selsChange(sels) {
        this.sels = sels;
      },

      // 跳转到详情
      godetail(item) {
        this.authoDetailShow = true;
        this.choosedAuthoDvc = Object.assign({}, item);
      },

      //修改sn，remars
      handelEdit(obj) {
        this.deviceInfo.id = obj.id;
        this.isEdit = true;
        this.authoShow = true;
        this.deviceInfo.mac = obj.mac;
        this.deviceInfo.remarks = obj.remarks;
        this.deviceInfo.sn = obj.sn;
      },

      closeMuitlBatch() {
        this.batchAuthShow = false;
        this.queryDevices();
      },

      // 格式化时间
      formatTime(row) {
        return row && row.createTime
          ? moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },

      // 表单提交
      submitDvc() {
        this.$refs.deviceInfoForm.validate((valid) => {
          if (valid) {
            if (this.isEdit) {
              const params = {
                deviceAuthorizeRecordId: this.deviceInfo.id,
                sn: this.deviceInfo.sn,
                mac: this.deviceInfo.mac,
                remarks: this.deviceInfo.remarks,
              };
              this.editDvc(params);
            } else {
              this.dvcAuthorize();
            }
            this.authoShow = false;
          }
        });
      },

      // 判断登陆身份
      async checkRole() {
        const role = this.user.roleCode;
        if (/admin/.test(role)) {
          this.isAdmin = true;
        } else if (/brands/.test(role)) {
          this.isProxy = true;
          this.filters.customer = this.user.customerId;
        }
      },

      // 授权设备
      async dvcAuthorize() {
        const dvc = this.deviceInfo;
        const res = await createDevice(dvc);
        if (res && res.errorCode === 0) {
          this.queryDevices();
          this.$notify.success({
            title: "成功",
            message: "授权成功",
          });
        }
      },

      // 删除和批量删除
      handleDel(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            delDvcAuthorize(row.mac).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                this.$notify.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },

      // 初始化设备
      initDvc() {
        const ids = this.sels.map((item) => item.mac).toString();

        this.$confirm("确认初始化所选设备？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            initDvcById(ids).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                this.$notify.success({
                  title: "成功",
                  message: "初始化成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },

      // 翻页
      handleCurrentChange(page) {
        this.pager.page = +page;
        this.queryDevices();
      },

      // 设置分页的size
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryDevices();
      },

      handleQuery() {
        this.pager.page = 1;
        this.queryDevices();
      },

      // 获取品牌商或经销商
      async queryBrands(key = "") {
        const resData = await searchProByCustomer(
          { key },
          { size: 999, page: 1 }
        );
        if (resData.errorCode === 0 && resData.data) {
          this.brandTypeList = resData.data.list || [];
        }
      },

      // 获取产品型号
      async queryDeviceType(key = "") {
        const resData = await searchDevicesByType(
          { key },
          { size: 999, page: 1 }
        );
        if (resData.errorCode === 0 && resData.data) {
          this.deviceTypeList = resData.data.list || [];
        }
      },

      //下载模板
      async downloadModel() {
        const resData = await getAuthorModel();
        if (resData && resData.errorCode === 0) {
          this.downloadUrl = API_ROOT + "/" + resData.data.file;
          this.$nextTick(() => {
            this.$refs.downloadA.click();
          });
        }
      },

      //change sn 和 remarks
      async editDvc(params) {
        const res = await changeDvcSn(params);
        if (res && res.errorCode == 0 && res.data) {
          this.queryDevices();
          this.$notify.success({
            title: "成功",
            message: "修改成功",
          });
        }
      },

      // 批量导出
      async exportExcel() {
        const resData = await exportAuthorize(this.filters.key);
        if (resData.errorCode === 0 && resData.data) {
          this.downloadUrl = API_ROOT + "/" + resData.data.file;
          Vue.nextTick(() => {
            this.$refs.downloadA.click();
          });
        }
      },

      // 获取所有的设备
      async queryDevices() {
        this.listLoading = true;
        const resData = await searchAuthoDvc(this.filters, this.pager);
        if (resData.data && resData.errorCode === 0) {
          this.deviceList = resData.data.list || [];
          this.total = resData.data.total;
        }
        this.listLoading = false;
      },

      volumeAuth() {
        this.checkRole();
        this.batchAuthShow = true;
      },
    },
    async created() {
      this.queryBrands();
      this.queryDevices();
      this.queryDeviceType();
    },
    watch: {
      authoShow(newval) {
        if (!newval) {
          this.isEdit = false;
          this.deviceInfo = {
            mac: "",
            sn: "",
            batch: "",
            productTypeId: "",
            remarks: "",
          };
        }
      },
    },
  };
</script>
